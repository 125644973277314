@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,400;1,500;1,900&display=swap');

html, body, #root {
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
}

iframe {
  height: 100%;
  width: 100%;
}