@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,400;1,500;1,900&display=swap);
:root{
  --grren: #84B77A !important;
  height: 100%;
}

h1 {
  text-transform: uppercase;
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal !important;
  font-weight: bold !important;
  line-height: 59px;
  letter-spacing: 0,05em;
  text-align: left;
}
h2 {
  color: #394246 !important;
  font-size: 48px !important;
  font-weight: bold !important;
}
h3 { 
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0,05em;
  text-align: left;  
}

button {
  border: none !important;
  background-color: rgb(112, 175, 112);
  color: white;
}

button.btn-cancel {
  background-color: #adb5bd !important;
}

a {
  color: rgb(112, 175, 112) !important;
  cursor: pointer;
}

img.click { 
  display: block;
  width: 100%;
  background: url(/assets/images/image-loading.gif) no-repeat center;
}

#room img.click {
  object-fit: cover;
}

#admin.noLogged > #footer {
  position: fixed;
  bottom: 0;
}

#admin input, #admin textarea {
  padding: 5px !important;
}

.selected {
  font-weight: bold !important;
}

.bg-grey { background-color: #F5F5F5 !important; }
.bg-white { background-color: white !important; }
.bg-dark { background-color: #292F32 !important; color: white !important; }

.white { color: white !important; }

.header {
  color: white;
}

.header h1, .header h3, .header h4, .header a {
  text-shadow: 1px 2px #000000b0 !important;
}

.header a:hover {
  text-decoration: none;
}

.logo {
  height: 46px !important;
}
.logo-footer {
  height: 40px !important;
}

.background {
  background-position: center;
  background-size: cover !important;
  background-repeat: no-repeat;
  min-height: 600px !important;
}

.thumb {
  width: 100%;
  background-position: center;
  background-size: cover !important;
  height: 512px !important;
}

.thumb-mini {
  width: 100%;
  background-position: center;
  background-size: cover !important;
}

.radius {
  border-radius: 24px !important;
}

.card {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  border: none !important;
}

.card-descriptions {
  left: 75%;
  position: relative;
}

.card-descriptions-phone {
  display: none;
}

.card-over {
  padding-right: 40%;
}

.click {
  cursor: pointer !important;
}

#prices h6 { font-weight: bold !important; }
#prices p { padding: 0px 0 6px 0 }
#map iframe {
  border: 0px !important;
}


@media screen and (max-width: 600px) {
  .card-over {
    padding-right: 0% !important;
    padding-bottom: 20px;
    margin-bottom: 20px !important;
  }

  .card-over > .card {
    height: 280px !important;
    margin-bottom: 20px;
  }

  .card-descriptions {
    background-color: red;
    display: none !important;
  }

  .card-descriptions-phone {
    height: 100% !important;
    display: block;
  }
}
html, body, #root {
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
}

iframe {
  height: 100%;
  width: 100%;
}
